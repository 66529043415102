import React, { useState } from "react"

import NavHeader from "./NavHeader"
import NavLinks from "./NavLinks"

const Nav = ({menuBtn}) => {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
  })

  const openMenuHandler = () => {
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
      })
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
      })
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
      })
    }
  }

  return (
    <>
      <NavHeader openMenu={openMenuHandler} state={state} menuBtn={menuBtn} />
      <NavLinks state={state} />
    </>
  )
}

export default Nav
