import React from "react"
import { Link } from "gatsby"

// Style
import "./Footer.scss"

const Footer = ({ site }) => {
  const Mailto = ({ email, subject, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}`}>
        {children}
      </a>
    )
  }

  return (
    <div className={`footer-container ${site}`}>
      <div className="footer-wrapper">
        <div className="footer-head">
          <h2>
            let's
            <br />
            collaborate
          </h2>
        </div>
        <div className="contact-info-container">
          <div className="info-item">
            <a
              href="https://www.google.de/maps/place/Stendaler+Str.+4,+10559+Berlin/@52.5328965,13.3469679,17z/data=!3m1!4b1!4m5!3m4!1s0x47a85175e13c9c49:0x6fc470b07148443!8m2!3d52.5328965!4d13.3491566"
              target="_blank"
            >
              <h3>Hemd und Fliege</h3>
              <p>Stendaler Str. 4, 10559 Berlin</p>
            </a>
          </div>
          <div className="info-item">
            <h3>Start a project</h3>
            <Mailto
              email="magic@hemdundfliege.com"
              subject="Just wanted to say hi."
            >
              <p>magic@hemdundfliege.com</p>
            </Mailto>
          </div>
          <div className="info-item">
            <ul>
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutz</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
