import React from "react"
import { Link } from "gatsby"

// Styles
import "./NavHeader.scss"

import logo from "../../images/hemd-und-fliege-logo.svg"

const NavHeader = ({ openMenu, state, menuBtn }) => {
  console.log(menuBtn, "<----- Nav Header")

  return (
    <header style={{ position: "absolute", top: 0 }}>
      <div className="header-container">
        <div className="wrapper">
          <div className="logo-container">
            <Link to="/">
              <img
                src={logo}
                alt="Hemd und Fliege | Berlin | Web Applications, Design, Branding, Communication"
              />
            </Link>
          </div>
          {
            state.clicked ? 
            <div className={`menu-btn open`} onClick={openMenu}>
              <div className="menu-btn__burger"></div>
            </div> : 
            <div className={`menu-btn ${menuBtn}`} onClick={openMenu}>
              <div className="menu-btn__burger"></div>
            </div>
          }
        </div>
      </div>
    </header>
  )
}

export default NavHeader
