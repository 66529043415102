import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";

import Nav from "./Navbar/Nav"
import Footer from "./Footer/Footer"

import "./layout.scss"

const Layout = ({ children, site, footer, menuBtn }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={`layout-container ${site}`}>
      <Nav menuBtn={menuBtn} />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
        >
          This site uses cookies to improve user experience...
        </CookieConsent>
        <main>{children}</main>
      <Footer site={footer} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
