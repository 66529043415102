import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap/dist/gsap"

// Styles
import "./NavLinks.scss"

const linkListOne = [
  { "title": "Home", "id": "home", "slug": "/" },
  { "title": "Concept", "id": "concept", "slug": "/concept" },
  { "title": "Works", "id": "works", "slug": "/works" },
  { "title": "Team", "id": "team", "slug": "/team" }
]

const socialList = [
  { "title": "Instagram", "id": "instagram", "slug": "https://www.instagram.com/hemdundfliege/" },
  { "title": "Behance", "id": "behance", "slug": "https://www.behance.net/hemdundfliege" },
  { "title": "Awwwards", "id": "awwwards", "slug": "https://www.awwwards.com/HemdundFliege/" },
  { "title": "Dribbble", "id": "dribbble", "slug": "https://dribbble.com/hemdundfliege" },
  // { "title": "LinkedIn", "id": "linkedIn", "slug": "#" },
  // { "title": "Xing", "id": "xing", "slug": "#" }
]

const NavLinks = ({state}) => {

  const Mailto = ({ email, subject, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}`}>{children}</a>
    );
  };

  gsap.registerPlugin()

  let bgAnimation = useRef()
  let listAnimation = useRef([])
  let socialNavOne = useRef()
  let socialNavTwo = useRef()

  listAnimation.current = []

  const addToList = list => {
    if (list && !listAnimation.current.includes(list)) {
      listAnimation.current.push(list)
    }
  }
    useEffect(() => {
    
    if (state.clicked === false) {

        gsap.to([socialNavOne, socialNavTwo], {
          duration: 0.8,
          css: {
            opacity: 0
          },
          stagger: 0.1,
          ease: "Power3.easeInOut",
        })
      
       gsap.to([listAnimation.current], {
        duration: 0.8,
        delay: 0.1,
        y: "110%",
        ease: "Power3.easeInOut",
        stagger: 0.1,
      })
      
      gsap.to(bgAnimation, {
        duration: 0.8,
        delay: 1.3,
        y: "-100%",
        ease: "power3.inOut",
      })

    } else if (state.clicked === true ||
      (state.clicked === true && state.initial === null)) {

      gsap.to([bgAnimation], {
        duration: 0.8,
        y: "0%",
        ease: "power3.inOut",
      })

      gsap.to([listAnimation.current], {
        delay: 0.4,
        duration: 0.8,
        y: "0%",
        ease: "Power3.easeInOut",
        stagger: 0.1,
      })

    gsap.to([socialNavOne, socialNavTwo], {
      duration: 0.8,
      delay: 1.5,
      css: {
        opacity: 1
      },
      stagger: 0.1,
      ease: "Power3.easeInOut",
    })
  }

  }, [state])

  return (
      <div ref={el => (bgAnimation = el)} className="menu-container">
        <div className="menu-wrapper">
          <div className="menu-links">
            <nav>
              <ul>
                {linkListOne.map(link => (
                  <li key={link.id}>
                    <div className="link-container" ref={addToList}>
                      <Link to={link.slug}>{link.title}</Link>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="social-links">
            <nav ref={el => (socialNavOne = el)}>
              <ul>
                {socialList.map(link => (
                  <li key={link.id}>
                    <div className="link-container">
                      <a href={link.slug} target="_blank">{link.title}</a>
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
            <nav ref={el => (socialNavTwo = el)}>
              <ul>
                <li>
                  <Mailto email="magic@hemdundfliege.com" subject="Just wanted to say hi.">
                    magic@hemdundfliege.com
                  </Mailto>
                </li>
                <li>
                  <address>
                    Stendaler Str. 4<br />
                    10559 Berlin
                </address>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
  )
}

export default NavLinks